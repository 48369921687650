html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

* {
  font-family: 'Questrial', sans-serif;
}

#atlwdg-trigger {
  z-index: 100;
}

